import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Value = makeShortcode("Value");
const Prop = makeShortcode("Prop");
const MenuTree = makeShortcode("MenuTree");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You will see some formatting on this site that is meant to help you understand the role of the text by emulating different visual styles. Below is the key to deciphering it all!`}</p>
    <p><kbd parentName="p">{`⌘ Cmd`}</kbd>{` This is a physical key on your keyboard.`}</p>
    <p />
    <Value variant="ae" mdxType="Value">100%</Value> this is a value you might enter.
    <p />
    <Prop variant="ae" mdxType="Prop">Scale</Prop> this is the property you might be changing.
    <p />
    <MenuTree tree={["Edit", "Copy"]} mdxType="MenuTree" /> this is a series of menu choices.
    <ol>
      <li parentName="ol">{`This is the `}<kbd parentName="li">{`Shift`}</kbd>{` `}<kbd parentName="li">{`S`}</kbd>{` key.`}<ul parentName="li">
          <li parentName="ul">{`This is the `}<kbd parentName="li">{`V`}</kbd>{` key.`}</li>
        </ul></li>
      <li parentName="ol">{`Test`}<ul parentName="li">
          <li parentName="ul">{`sub test`}</li>
        </ul></li>
    </ol>
    <ul>
      <li parentName="ul">{`I like cheese this is the `}<kbd parentName="li">{`S`}</kbd>{` key.`}<ul parentName="li">
          <li parentName="ul">{`goat is the `}<kbd parentName="li">{`S`}</kbd>{` key.`}</li>
          <li parentName="ul">{`brie`}</li>
        </ul></li>
      <li parentName="ul">{`pizza is also good`}</li>
      <li parentName="ul">{`and apples.`}</li>
    </ul>
    <p><kbd parentName="p">{`Cmd`}</kbd></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      